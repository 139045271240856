/**
 * Defines a store for managing UX related states across the application.
 * Primarily used to persist the state of whether the user has hidden certain UX tips.
 */
export const useUxStore = defineStore('ux', {
  /**
   * State: Object to store visibility state of UX tips.
   * Uses local storage to persist states across sessions.
   * Each tip's visibility is identified by a unique `tipId` string.
   * Boolean value indicates whether the tip has been hidden (`true`) or not (`false`).
   */
  state: () => ({
    tipsHidden: useLocalStorage<{[id:string]: boolean}>('tipsHidden', {})
  }),

  /**
   * Action to update the visibility state of a UX tip to hidden.
   * @param tipId - The unique identifier for the UX tip.
   */
  actions: {
    setTipHidden (tipId: string) {
      this.tipsHidden[tipId] = true
    },

    /**
     * Action to update the visibility state of a UX tip to visible.
     * @param tipId - The unique identifier for the UX tip.
     */
    setTipVisible (tipId: string) {
      this.tipsHidden[tipId] = false
    }
  }
})
