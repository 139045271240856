/**
 * Composable function for managing a specific UX tip's visibility.
 * @param tipId - The unique identifier for the UX tip being managed.
 * Provides functionality to check and update the visibility of a specific UX tip.
 */
export function useTips (tipId: string) {
  const uxStore = useUxStore()

  /**
   * Checks if the specified UX tip has been marked as hidden.
   * @returns `true` if the tip is hidden, `false` otherwise.
   */
  const isTipHidden = computed(() => {
    return uxStore.tipsHidden[tipId] || false
  })

  /**
   * Marks the specified UX tip as hidden.
   */
  const markTipAsHidden = () => {
    uxStore.setTipHidden(tipId)
  }

  /**
   * Marks the specified UX tip as visible.
   */
  const markTipAsVisible = () => {
    uxStore.setTipVisible(tipId)
  }

  return { isTipHidden, markTipAsHidden, markTipAsVisible }
}
